import { get } from './http'

export const getProvince = () => {
  const url = '/district/province'
  return get(url)
}

export const getCity = (id) => {
  const url = '/district/city/' + id
  return get(url)
}

export const getArea = (id) => {
  const url = '/district/area/' + id
  return get(url)
}
